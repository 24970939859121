<template>
    <marketing-layout>
        <marketing-hero>
            <h1 class="fw-bold heading-3 text-responsive mb-3 text-md-center">
                {{ $t('pages.marketing.mobileApps.title') }}
            </h1>
            <h2 class="fw-light h5 text-responsive mb-0 text-md-center">
                {{ $t('pages.marketing.mobileApps.subTitle') }}
            </h2>
        </marketing-hero>
        <section-block class="pt-0">
            <div class="container">
                <div class="col-12 mt-3 mt-md-5">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-2">
                            <a
                                href="https://apps.apple.com/us/app/aven-card/id1541705487?itsct=apps_box&itscg=30200"
                                class="app-button"
                            >
                                <img
                                    src="@/assets/images/pages/marketing/app/logo-apple.svg"
                                    :alt="$t('pages.marketing.mobileApps.iphone')"
                                >
                                <span>{{ $t('pages.marketing.mobileApps.iphone') }}</span>
                            </a>
                        </div>
                        <div class="col-12 col-lg-6 mb-2">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.aven.app&hl=en_US"
                                class="app-button"
                            >
                                <img
                                    src="@/assets/images/pages/marketing/app/logo-play-store.svg"
                                    :alt="$t('pages.marketing.mobileApps.android')"
                                >
                                <span>{{ $t('pages.marketing.mobileApps.android') }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section-block>
    </marketing-layout>
</template>

<script>
    import Marketing from '../../layouts/Marketing'
    import MarketingHero from '@/components/MarketingHero'
    import SectionBlock from '@/components/SectionBlock'

    export default {
        name: 'MobileApps',
        metaInfo: {
            title: 'Get The Aven App',
            meta: [{ name: 'description', vmid: 'description', content: 'Download the Aven Card app to easily view and manage your card. Available for iPhone and Android.' }],
        },
        mounted: function () {
            this.$logEvent('view_mobile_apps')
        },
        components: {
            'marketing-layout': Marketing,
            MarketingHero,
            SectionBlock,
        },
    }
</script>

<style lang="scss" scoped>
    .app-button {
        border: 1px solid white;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        padding: 24px 0;
        color: $white;

        img {
            margin-bottom: $spacer / 2;
        }
    }
</style>
